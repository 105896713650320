import "./second.css";
import SocialMediaLinks from "./SocialMediaLinks";
import { React} from "react";



const Second = () => {
  return <div className="second">
      <div  className="globe-text">   
      <SocialMediaLinks iconSize="2x"/>
      </div>
    <div className="globe-text-credit">
      -- WIRED (when I wrote this)
    </div>
    <div className="about-title">BAMBÜ <span>a </span>Brazilian <span> electronic </span> music duo <br />
    mirrors the <span>rapid growth</span> of <br />
   bamboo <span>in</span> their <span>meteoric</span> rise. Infusing <br />
 <span>pulsating rhythms</span> with infectious <span>energy, </span><br/>
  they<span>create</span>  a global sonic <span>journey.</span>
    </div>


  </div>;
}

export default Second;
