import './App.css';
// import Eight from './components/Eight/Eight';
import Five from './components/Five/Five';
// import Fourth from './components/Fouth/Fourth';
import Home from './components/Home/Home';
// import Nine from './components/Nine/Nine';
import Second from './components/Second/Second';
// import Seven from './components/Seven/Seven';
// import Six from './components/Six/Six';
// import Contact from './components/Contact/contact';
import Ten from './components/Ten/Ten'
// import Third from './components/Third/Third';


function App() {
  return (
    <div className="App">
      <Home/>
      <Second/>
      {/* <Third/> */}
      {/* <Fourth/> */}
      <Five/>
      {/* <Six/> */}
      {/* <Seven/> */}
      {/* <Eight/> */}
      {/* <Nine/> */}

      {/* <Contact/> */}
      <Ten/>
    </div>
  );
}

export default App;
