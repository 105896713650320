import "./five.css";
import STOCK from "../img/5.jpeg";
import MASK from "../img/mask.png"
import React from "react";

const Five = () => {
  return <div className="five">
    <hr className="hr" />
    <div className="download">BAMBÜ</div>
    <div className="download-about">
        We aspire to ignite joy, and <br />
        spark an infectious energy  <br />
        through lively music. <br />
        spreading the spirit of <br/>
        celebration through  <br/>
        dynamic soundscapes.
        {/* <a href="https://www.instagram.com/bambu/" className="down">Download ➤</a> */}
    </div>
    <div className="stock">
        <img src={MASK} alt="" className="stock-BAMBÜ" />
    </div>
  </div>;
};

export default Five;
