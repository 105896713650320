import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faSpotify, faYoutube, faApple, faCompactDisc, faSoundcloud, faTiktok } from '@fortawesome/free-brands-svg-icons';

const SocialMediaLinks = ({ iconSize }) => {
  const [isFacebookHovered, setIsFacebookHovered] = useState(false);
  const [isInstagramHovered, setIsInstagramHovered] = useState(false);
  // const [isTwitterHovered, setIsTwitterHovered] = useState(false);
  const [isSpotifyHovered, setIsSpotifyHovered] = useState(false);
  const [isYoutubeHovered, setIsYoutubeHovered] = useState(false);
  const [isAppleHovered, setIsAppleHovered] = useState(false);
  // const [isBeatportHovered, setIsBeatportHovered] = useState(false);
  const [isSoundcloudHovered, setIsSoundcloudHovered] = useState(false);
  const [isTiktokHovered, setIsTiktokHovered] = useState(false);

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '500px',
    margin: '0 auto',
  };

  const linkStyle = {
    color: 'black',
    textDecoration: 'none',
    margin: '0 5px',
    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
  };

  return (
    <div style={containerStyle}>
      <a
        href="https://www.facebook.com/domdollamusic"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isFacebookHovered ? 'scale(1.2)' : 'scale(1)', color: isFacebookHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsFacebookHovered(true)}
        onMouseLeave={() => setIsFacebookHovered(false)}
      >
        <FontAwesomeIcon icon={faFacebook} size={iconSize} />
      </a>
      <a
        href="http://instagram.com/domdolla"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isInstagramHovered ? 'scale(1.2)' : 'scale(1)', color: isInstagramHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsInstagramHovered(true)}
        onMouseLeave={() => setIsInstagramHovered(false)}
      >
        <FontAwesomeIcon icon={faInstagram} size={iconSize} />
      </a>
      {/* <a
        href="https://twitter.com/domdolla"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isTwitterHovered ? 'scale(1.2)' : 'scale(1)', color: isTwitterHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsTwitterHovered(true)}
        onMouseLeave={() => setIsTwitterHovered(false)}
      >
        <FontAwesomeIcon icon={faTwitter} size={iconSize} />
      </a> */}
      <a
        href="https://open.spotify.com/artist/205i7E8fNVfojowcQSfK9m?si=hZl4L1c-RJiR7hHooy-S8g"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isSpotifyHovered ? 'scale(1.2)' : 'scale(1)', color: isSpotifyHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsSpotifyHovered(true)}
        onMouseLeave={() => setIsSpotifyHovered(false)}
      >
        <FontAwesomeIcon icon={faSpotify} size={iconSize} />
      </a>
      <a
        href="https://www.youtube.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isYoutubeHovered ? 'scale(1.2)' : 'scale(1)', color: isYoutubeHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsYoutubeHovered(true)}
        onMouseLeave={() => setIsYoutubeHovered(false)}
      >
        <FontAwesomeIcon icon={faYoutube} size={iconSize} />
      </a>
      <a
        href="https://music.apple.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isAppleHovered ? 'scale(1.2)' : 'scale(1)', color: isAppleHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsAppleHovered(true)}
        onMouseLeave={() => setIsAppleHovered(false)}
      >
        <FontAwesomeIcon icon={faApple} size={iconSize} />
      </a>
      {/* <a
        href="https://www.beatport.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isBeatportHovered ? 'scale(1.2)' : 'scale(1)', color: isBeatportHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsBeatportHovered(true)}
        onMouseLeave={() => setIsBeatportHovered(false)}
      >
        <FontAwesomeIcon icon={faCompactDisc} size={iconSize} />
      </a> */}
      <a
        href="https://soundcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isSoundcloudHovered ? 'scale(1.2)' : 'scale(1)', color: isSoundcloudHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsSoundcloudHovered(true)}
        onMouseLeave={() => setIsSoundcloudHovered(false)}
      >
        <FontAwesomeIcon icon={faSoundcloud} size={iconSize} />
      </a>
      <a
        href="https://www.tiktok.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...linkStyle, transform: isTiktokHovered ? 'scale(1.2)' : 'scale(1)', color: isTiktokHovered ? 'rgb(96, 218, 69)' : 'black' }}
        onMouseEnter={() => setIsTiktokHovered(true)}
        onMouseLeave={() => setIsTiktokHovered(false)}
      >
        <FontAwesomeIcon icon={faTiktok} size={iconSize} />
      </a>
    </div>
  );
};

export default SocialMediaLinks;