import SocialMediaLinks from "../Second/SocialMediaLinks";
import "./ten.css";

import React from "react";

const Ten = () => {  const containerStyle = {
  textAlign: 'center',
  fontFamily: 'Formular, Arial',
  backgroundColor: 'black',
  color: 'white',
  height: '100vh', // Ensure full height
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

};

const emailLinkStyle = {
  color: 'white',
  textDecoration: 'none',
  transition: 'color 0.3s ease-in-out',
  align: 'center',
};

const emailContainerStyle = {
  margin: '0', // Reset margin for the email paragraphs
};

const handleEmailHover = (e) => {
  e.target.style.color = 'grey';
};

const handleEmailLeave = (e) => {
  e.target.style.color = 'white';
};

return (
  <div style={containerStyle}>
    <h1>MGMT</h1>
    <p style={emailContainerStyle}>
      <a
        href="mailto:TIM.BLACKGROVE@BAMBU.HOUSE"
        style={emailLinkStyle}
        onMouseEnter={handleEmailHover}
        onMouseLeave={handleEmailLeave}
      >
       WORLD • TIM.BLACKGROVE@BAMBU.HOUSE
      </a>
      <a
        href="mailto:JOHN.BLACKGROVE@BAMBU.HOUSE"
        style={emailLinkStyle}
        onMouseEnter={handleEmailHover}
        onMouseLeave={handleEmailLeave}
      >
       AMERICAS • JOHN.BLACKGROVE@BAMBU.HOUSE
      </a>
    </p>
    <br />

    <h1>BOOKINGS</h1>
    <p style={emailContainerStyle}>

      <a
        href="mailto:RHPRITS@BAMBU.COM"
        style={emailLinkStyle}
        onMouseEnter={handleEmailHover}
        onMouseLeave={handleEmailLeave}
      >
       DAVE • RHPRITS@BAMBU.COM
      </a>
    </p>
    <br />
    <h1>PRESS</h1>
    <p style={emailContainerStyle}>
      <a
        href="mailto:DAVE@BAMBU.COM"
        style={emailLinkStyle}
        onMouseEnter={handleEmailHover}
        onMouseLeave={handleEmailLeave}
      >
        DAVE • DAVE@BAMBU.COM
      </a>
    </p>
  </div>
);
};

export default Ten;
