import "./home.css";
// import HERO from "../img/hero.mp4";
// import HERO2 from "../img/hero2.jpg"
import HERO3 from "../img/hero3.gif"
import React from "react";
import { Link, animateScroll as scroll } from 'react-scroll';
// import AnimatedText from '../Home/AnimatedText';

const ScrollToBottomLink = () => {
  const scrollToBottom = () => {
    scroll.scrollToBottom({ smooth: true });
  };

  return (
    <Link to="bottom" smooth={true} duration={500} onClick={scrollToBottom}>
     Contact
    </Link>
  );
};



const ImageGallery = () => {
  // Array of image URLs
  const imageUrls = [
    HERO3,HERO3,HERO3,HERO3,
    // 'url-to-your-image-2.gif',
    // 'url-to-your-image-3.gif',
    // 'url-to-your-image-4.gif',
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {imageUrls.map((imageUrl, index) => (
        <img
          key={index}
          src={imageUrl}
          alt={`Panda Image ${index + 1}`}
          style={{ width: '100px', height: '100px' }} // Adjust the width and height as needed
        />
      ))}
    </div>
  );
};




const Home = () => {

  return <div className="home">
    <div className="navbar">
        <div className="logo">BAMBÜ</div>
    <ul>
        
        <li><ScrollToBottomLink/></li>
        <li><a href="https://studio.bambu.house">Creative</a></li>
        {/* <li><a href="https://www.instagram.com/bambu/">Tour</a></li> */}
        {/* <li><a href="https://www.instagram.com/bambu/">About us</a></li> */}
        {/* <li><a href="https://www.instagram.com/bambu/">Products</a></li> */}
    </ul>
    </div>
    <div className="hero-section">
        <div className="hero-logo">BAMBU  </div>
        <div className="video">
          <ImageGallery />
        {/* <iframe title="vimeo-player" src="https://player.vimeo.com/video/869626549?h=4abe38dba0" width="1280 " height="720" frameborder="0"    allowfullscreen></iframe> */}
          {/* <img src={HERO2} className="hero" alt="panda illustration" /> */}
            {/* <video src={HERO} className="hero" autoPlay muted loop></video> */}
        </div>
    </div>
  </div>;
};

export default Home;
